.p-accordion-header-link:focus{
    box-shadow: none !important;
}
  
.p-accordion-content{
    padding: 0.25rem !important;
}
  
.p-accordion-tab{
    padding-bottom: 1rem !important;
}

.no-toggle{
    padding: 0.75rem 1.25rem;
    border: 1px solid #dee2e6;
    color: #6c757d;
    background: #f8f9fa;
    font-weight: 700;
    border-radius: 6px;
    margin-bottom: 1.25rem;
}